import React from 'react';

import PalBasics from './PalBasics';
import IsrBasics from './IsrBasics';

function Basics() {
    return (
        <section>
            <article className="container">

                <h3> Before we start</h3>
                <h4>we should provide some <span className="diff">context</span> for the involved parties in order to understand their perspectives and who they are so that each event can be seen through <span className="diff">their eyes.</span></h4>
         
            </article>
            
            <article className="basics">

                <PalBasics />
                        
                <IsrBasics />            

            </article>


        </section>
    )
}

export default Basics
