
import './App.css';

import Timeline from './components/Timeline';
import Header from './components/header';
import Footer from './components/footer';



function App() {
  return (
  <>
    <Header />
    <Timeline />
    <Footer />
  </>
  );
}

export default App;
