import React, {useState} from 'react'
import isr_flag from '../images/isr_flag.jpg'

function IsrBasics() {

    const sections =['description','origin','claim','wants'];

    const [hideDescription,setHideDescription]= useState(true);
    const [hideOrigin,setHideOrigin]= useState(true);
    const [hideClaim,setHideClaim]= useState(true); 
    const [hideWants,setHideWants]= useState(true);

     const handleClick=(section)=>{
        switch(section){
            case sections[0]: setHideDescription(!hideDescription);break;
            case sections[1]: setHideOrigin(!hideOrigin);break;
            case sections[2]: setHideClaim(!hideClaim);break;
            case sections[3]: setHideWants(!hideWants);break;
            default:;
        }
    }

     
       
       

    return (
        <div className="isr-basics">
            <h3>A brif about the Israeli case</h3>

                <img  src={isr_flag} alt="isr-flag" className="cover isr-flag"></img>

            <div className="expandable">
                <h4 className="diff">Who are the Israeli people?</h4>
                <span className="expand" onClick={()=>handleClick(sections[0])} > {`${hideDescription? '+':'-'}`} </span>
            </div>

            <div className={hideDescription?"hide":"show"}>
                    <p>First we need to understand what we mean by Israeli and Jewish.</p>
                    
                    <p><span className="diff">An Israeli</span> is someone holding the Israeli nationality, they may not necessarily be ethnically or religiously jewish, they could be Arab, African, Theists or Atheists...etc.
                        There are many Arabs (muslims, christians, druz..etc) living with Israeili citizenship, <a href="https://www.cfr.org/backgrounder/what-know-about-arab-citizens-israel" target="_" className="link">about 21% of the population.</a> They are called the Arabs of 48 (more on that in the timeline)</p>    
                    
                    <p>  <span className="diff">A Jew</span> is someone who is either ethnically jewish or religiously jewish. One can be ethnically Jewish but not follow the jewish faith.
                         Therefore, Jew is a word used to describe a religion and a group of people. Here we will use it in the <span className="diff">ethnic</span>  sense.
                         
                    </p>

                    <p> Israel aims to be a state/home made for the (at least ethnically) jewish people of the world. So, who are the Jewish people? </p>

                    <p> <a href="https://www.familysearch.org/wiki/en/Jewish_Genealogy_Research" target="_" className="link diff">There are four main ethnic divisions of jews:</a> </p>

                    <ul className="ListBasics">
                        <li className="text-ListItem">
                        <span className="diff">Ashkenazi:</span> Jews that settled in central and eastern europe, they are by far the biggest group (about 80% of all jews in and out of israel), they speak Yiddish.
                        </li>
                        <li className="text-ListItem">
                        <span className="diff">Sephardic:</span> Jews who descend from those who were expelled from Spain, Portugal and Italy in the 14/1500s. Most of these Jews spoke a version of Spanish called Ladino.
                        </li>
                        <li className="text-ListItem">
                        <span className="diff">Mizrahi:</span> Descendants of middle eastern jews. The term Mizrahi is most commonly used in Israel to refer to Jews who trace their roots back to Muslim-majority countries. Mizrahi and Sepharadic are often inter mingled.
                        </li>
                        <li className="text-ListItem">
                        <span className="diff">African:</span> most famously from Ethiopia, many of whom immigrated to Israel. 
                        </li>
                    </ul>
                    <p> <span className="diff">Note:</span> there are other smaller groups of jews such as Romaniote, Marrano, Indian, Chinese and Crypto Jews.</p>
                
                   

                    <br></br>

                    <div className="expandable">
                            <h4 className="diff">But where do current day jews come from?</h4>
                            <span className="expand" onClick={()=>handleClick(sections[1])} > {`${hideOrigin? '+':'-'}`} </span>
                    </div>


                        <div className={hideOrigin?"hide":"show"}>

                            <p> Currently, there are <a href="https://www.sciencemag.org/news/2010/06/tracing-roots-jewishness" target="_" className="link diff">two</a> main theories as to where the modern jews come from, here is a summary:</p>

                            <ul className="ListBasics">

                                <li className="text-ListItem">

                                <span className="diff">Conversion theory:</span> Some writers, notably Arthur Koestler in his 1976 book <span className="diff">The Thirteenth Tribe</span>, have argued that the Ashkenazis (vast majority of jews) stem from a Turkic tribe in Central Asia called the Khazars, who converted to Judaism in the 8th century. 
                                And historian Shlomo Sand of Tel Aviv University in Israel argues in his book <span className="diff">The Invention of the Jewish People</span>, that most modern Jews do not descend from the ancient Land of Israel but from groups that took on Jewish identities long afterward.
                                In this Narrative, the other notable types of Jews are either of middle eastern descent who have been living in the holly land for thousands of years and other jews such as african/indian jews migrated from the middle east and mixed with the population.
                                </li>
                                
                                <li className="text-ListItem">
                                    
                                <a href="https://www.cell.com/ajhg/fulltext/S0002-9297(10)00246-6#" target="_" className="link diff">The holly land theory:</a> This theory does not support the above ideas however, as recent studies suggest that Jewishness, including the Ashkenazi version, has deep genetic roots.
                                In what its authors claim is the most comprehensive study thus far, a team led by geneticist Harry Ostrer of the New York University School of Medicine concludes today that all three Jewish groups—Middle Eastern, Sephardic, and Ashkenazi—share genomewide genetic markers that distinguish them from other worldwide populations. 
                                In Israel, this is the most widely supported Idea, mainly because it ligitimizes the right to ownership of the land.                     
                                </li>

                            </ul>

                            <h4 className="diff">Analysis and notes</h4>

                            <p>
                                It is worth noting for transparancy reasons that the linked study in the holly land theory above is not performed/funded by third parties, qouting the study's acknowledgement section.
                                "This study was supported, in part, by the Lewis and Rachel Rudin Foundation, the Iranian-American Jewish Federation of New York, the U.S.-Israel Binational Science Foundation, the US National Institutes of Health (5 U54 CA121852), and Ruth and Sidney Lapidus."
                                This is not to question the science in the study (this is up to peer reviews) but it is fair to know that the parties involved have reasons to prove the hypothesis as true.
                                However, other researchers praise the work. Such as Noah Rosenberg of the University of Michigan, and Sarah Tishkoff, a geneticist at the University of Pennsylvania.
                            e.g: Rosenberg says "It's the largest to date on this question, and using the IBD method to tackle it is "innovative" Nevertheless, says Rosenberg, although the study "does not appear to support" the Khazar hypothesis, it doesn't entirely eliminate it either.
                            </p>

                            <p>
                            On the other hand, some scientists criticize the migration theories in some of their studies due to sampling size or analysis techniques and praise the other theory more for its scientific accuracy
                            such as  <a href="https://avotaynuonline.com/2020/03/the-genetic-origins-of-ashkenazi-jews/" target="_" className="link diff">this article.</a> 
                            </p>

                            <p>
                            <span className="diff">Both theories do not address</span> the status of groups whose claim to Jewishness has been controversial, such as Ethiopian Jews, the Lemba from southern Africa, and several groups from India and China. 
                            But given the findings of a common genetic origin plus a complex history of admixture, geneticist David Goldstein of Duke University in Durham, North Carolina, says that 
                            <span className="diff">neither</span> of the "extreme models"—those that see Jewishness as entirely cultural or entirely genetic—"are correct." <span className="diff">Rather</span>, Goldstein says, "Jewish genetic history is a complicated mixture of both genetic continuity from an ancestral population and extensive admixture."
                            </p>
                        </div>   
                    </div> 
                    <br></br>

            <div className="expandable">
                <h4 className="diff">What is their claim to the land?</h4>
                <span className="expand" onClick={()=>handleClick(sections[2])} > {`${hideClaim? '+':'-'}`} </span>
            </div>

                <div className={hideClaim?"hide":"show"}>
                    
                    <p>
                        There are five basic claims made by Israelies for the land:
                    </p>

                    <ul className="ListBasics">

                        <li className="text-ListItem">
                        <span className="diff">The discrimination claim says:</span> 
                        Throughout history, Jews have been mistreated, expelled, discriminated against or have been subjects of genocide such as the Holocaust and Stalinist russia.
                        Since everyone hates Jews or is unjust to them, they deserve a land of their own away from all the discrimination and hate where they can live in peace.
                        </li>
                        
                        <li className="text-ListItem">
                        <span className="diff">The historical claim says:</span> 
                        The Jewish people are the actual rightful owners to the land because they have been expelled from their land a long time ago and they simply returned to it as the descendants with historical ownership.
                        Further, It was a dead empty desert before they returned and they are the ones who gave it life.
                        </li>

                        <li className="text-ListItem">
                        <span className="diff">The religious claim says:</span> 
                        Israel is the Jewish holly land which God has promised for the jews, this land is actually given by God for them so they deserve it.
                        </li>

                        <li className="text-ListItem">
                        <span className="diff">The legal claim says:</span> 
                        Israel was given to the Jews by the british empire in 1917 (belford's declaration) which owned the land after siezing it from the Ottomans who lost in WW1. That makes the land fairly and legally owned by the Jews.
                        It is not their fault that the people living there were forced into this situation as the jews were running away from genocide in europe, they had to take the deal. 
                        In Addition, they Jews tried to co-exist with the natives (arabs) but they rejected them and waged war in 1948 which Israel won and legally occupied the land by winning a war of self defense. 
                        </li>

                        <li className="text-ListItem">
                        <span className="diff">The political claim says:</span> 
                        Israel is recognized by the almost all the nations in the UN, including the arab states who previously rejected it. It has global recognition and soveriegnty.
                        The millions of Jews living there are not going anywhere as they have been there for generations and have built lives, schools, cities and importantly, the only stable democracy and the strongest military in the region.
                        </li>

                    </ul>

                </div>
                    <br></br>

            <div className="expandable">
                <h4 className="diff">What do they want?</h4>
                <span className="expand" onClick={()=>handleClick(sections[3])} > {`${hideWants? '+':'-'}`} </span>
            </div>

                <div className={hideWants?"hide":"show"}>
                    <p>
                    The Israelies say the Jews deserve a place to call home. A place full of prosperity, safety and peace for the jews with no prejudice or discrimination.
                    that place is their holly land and they want to be left alone and to live in peace.
                    </p>
                </div>
                   

        
        </div>
    )
}

export default IsrBasics
