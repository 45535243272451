import React,{useEffect,useContext} from 'react';
import { Context } from './provider';
import Item from './Item';
import AOS from 'aos';
import 'aos/dist/aos.css';



function Timeline() {

  const {option,display}  = useContext(Context);
  

    useEffect(()=>{
        AOS.init();
    },[]);

  


  switch(option){

    case("gen"):
    
    console.log("generic data"); 
    return(
      <h1> Generic data</h1>
    );
   
    default:
      return (
        <div>
         
    
          <section className="timeline">
            <h1 className="timeline-title">   Events: {option}    </h1>
            <ul>
              {display.map((item)=>( 
                
                <Item key={item.id} date={item.date} evidence={item.evidence} desc={item.desc} details={item.details} references={item.ref} />
                
                
                ))} 
              </ul>
        
          </section>
    
          
        </div>
        );
  }
    // return (
    // <div>
     

    //   <section className="timeline">
    //     <h1 className="timeline-title">   All events timeline     </h1>
    //     <ul>
    //       {Alldata.map((item)=>( 
            
    //         <Item key={item.id} date={item.date} evidence={item.evidence} desc={item.desc} references={item.ref} />
            
            
    //         ))} 
    //       </ul>
    
    //   </section>

      
    // </div>
    // );
}

export default Timeline
