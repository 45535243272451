import React from 'react'

function Explanation() {
    return (
        <article className="container">
 
            <h3>What is the starting point of the timeline?</h3>

            <p> Given the brief introduction above and with enough context from the resources presented,
                one would have a decent understanding of the way things were for both parties before the first event on the timeline.
                It would be rational to start the timeline when Jews first started moving into Palestine in a guided or intentional effort to establish Jewish presence there.
                That is because, that really is the birth of the Jewish immigration <span className="diff">Idea</span> which is around the time of the first Aliyah (immigration).
            </p>

            <br></br>

            <p>
                There have been some attempts to call for a "home for the jewish people" (e.g. 1839  petition by Moses Montefiore to Sa'id of Egypt for a Jewish homeland in the region of Palestine and the efforts of jewish emancipation in Europe) 
                but it is widely recognized even in Israel that the founding father of the idea is <a href='https://www.myjewishlearning.com/article/theodor-herzl/' target='_'><span className="diff link">Theodore Herzl with the book "A Jewish state".</span></a> 
                Therefore, After a brief explanation about the first Aliyah this is where the timeline will take off.
            </p>

            <br></br>

            <p> An attempt was made to be as objective and fair to all parties as possible so a timeline approach was chosen as a simple layout of events in sequence. 
            Each card is a moment in time or an event. It contains the date, visual evidence (if available) and a brief description.
             All evidence for any event are presented as links at the bottom of the card.
            <span className="diff">Hopefully after reading through this timeline, you should ask yourself: </span> </p>
            
             <br></br>

        <h1>Is it complicated?</h1>

    
    </article>
    )
}

export default Explanation
