import React from 'react'

function Closing() {
    return (
        <div>
            
            <h3 className="diff">What do you think? </h3>

            <div className= "closing">
                <p>
                    After reading all this, <span className="diff">congratulations,</span> you have come a long way. The conversation now moves to you.
                    
                </p>
                <ul className="ListBasics">
                   
                    <li className='text-ListItem'>
                    What do you think could be a valid solution here?

                    </li>
                   
                    <li className='text-ListItem'>
                    do you think there is hope for peace? 

                    </li>
                   
                    <li className='text-ListItem'>
                    what is your role in a situation like this?
                    </li>
                   
                    <li className='text-ListItem'>
                    what can you do as a citizen of the world?

                    </li>
                </ul>

                <p>
                    These questions are not meant to hold you accountable or point fingers, it is simply meant to  <span className="diff">probe</span> your brain.
                    consider your  <span className="diff">consumption habits</span>: what kind of companies are you paying? what are they doing in this situation? is your money being used to harm anyone or add to the injustice or violence?, 
                    your  <span className="diff">political decisions</span>: what are the policies of who you vote for? can you add political pressure in your rgion to do/improve something? or at least make it less bad, 
                    the general intuition/sentiment you have about this whole situation: 
                    this may help  <span className="diff">raise awareness</span> for the issue, fix wrong sentiments, correct facts, shed light on whats important and keep the conversation alive which may end up having enough people talking about it enough to find solutions and hopefully apply them.

                </p>

                

                
            </div>  
            
            <h3 className="diff"> Keep the conversation alive, don't let it die because it's "complicated"</h3>  


        </div>
    )
}

export default Closing
