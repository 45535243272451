import React, {useState} from 'react'
import pal_flag from '../images/pal_flag.png'
import ShrinkingMap from '../images/ShrinkingMap.jpg'
import bef_1 from '../images/Pal_before_1.JPG'
import bef_2 from '../images/Pal_before_2.JPG'
import bef_3 from '../images/Pal_before_3.JPG'
import TWO_STATES from '../images/TWO_STATES.jpg'


function PalBasics() {
    const PalSections =['description','origin','claim','wants'];
    const [hideDescriptionPal,setHideDescriptionPal]= useState(true);
    const [hideOriginPal,setHideOriginPal]= useState(true);
    const [hideClaimPal,setHideClaimPal]= useState(true);
    const [hideWantsPal,setHideWantsPal]= useState(true);

     const handleClick=(section)=>{
        switch(section){
            case PalSections[0]: setHideDescriptionPal(!hideDescriptionPal);break;
            case PalSections[1]: setHideOriginPal(!hideOriginPal);break;
            case PalSections[2]: setHideClaimPal(!hideClaimPal);break;
            case PalSections[3]: setHideWantsPal(!hideWantsPal);break;
            default:;
        }
    }

    return (
        <div className="pal-basics">    

            <h3>A brif about tha Palestinean case</h3>

                <img  src = {pal_flag} alt="pal-flag" className="cover"></img>

            <div className="expandable">

                    <h4 className="diff" >Who are the Palestenian people? </h4> 

                    <span className="expand" onClick={()=>handleClick(PalSections[0])} > {`${hideDescriptionPal? '+':'-'}`} </span>

            </div>

                <div className={hideDescriptionPal?"hide":"show"}>

                    <p>
                        First we need to understand that Palestenians are not all Moslem, even before the first Aliyah to Israel, there have been Moslems, christians and Jews living in Palestine.
                        Palestine is considered a holly land for the 3 religions and so, all of them had lived there some times in joined communities and some times in separate ones. 
                    </p>
                    <p>
                        The Palestenians are considered Arab people. The land itself was controlled by many empires and regions throughout history, the romans, Arabs, Ottomans or the British.
                        <a href="https://www.nationalgeographic.com/history/article/dna-from-biblical-canaanites-lives-modern-arabs-jews" target="_" className="link diff">Recent studies</a> show that many Palestenian Arabs and Jews have <a href="https://www.haaretz.com/science-and-health/palestinians-and-jews-share-genetic-roots-1.5411201" target="_" className="link diff">common ancestral</a> links to the canaanites and the Israelites. Some others show that many are descendants of Arabs and europeans.
                        The truth probably lies somewhere in the middle whereby Palestenians (like Israelies) are an ethnic mixture but share some genetic notes to a common ancestoral group.
                    </p>
                    <p> 
                        In 1850, Palestine had about 350,000 inhabitants. Roughly 85% were Muslims, 11% were Christians and 4% Jews.
                        Often times, people get lost in debating the history of names of the land and where people come from. We are mainly interested in giving a background to the type of people living in this area.
                    </p>
                    <h4 className="diff"> What was Palestine like before Israel?</h4>
                   
                    <p>
                    images and reports around that period show a land living in peace with mixed peoples. That is to say the Moslems, christians and Jews lived in semi harmonious state.
                    much of the land was used for farming, though there were deserts and cities alike.
                    </p>
                    
                    <br></br>
                    <img  src = {bef_1} alt="pal-flag" className="cover"></img>
                    <br></br>
                    <img  src = {bef_2} alt="pal-flag" className="cover"></img>
                    <br></br>
                    <img  src = {bef_3} alt="pal-flag" className="cover"></img>

                   

                </div> 

                    <br></br>

            <div className="expandable">

                <h4 className="diff">Their claim to the land</h4> 
                
                <span className="expand" onClick={()=>handleClick(PalSections[2])} > {`${hideClaimPal? '+':'-'}`} </span>
            
            </div>

            <div className={hideClaimPal?"hide":"show"}>

                    <p>
                        The palestinians claim the land is simply that they have been living there for generations, self governing or not, generations have existed in this land that was occupied by arabs.
                        Suddenly, they were forced to leave or share with a foreign group that has military might. They say the refugee crisis from 1948 and the contineous evictions and settlements to this day are a testament to the fact that  Israelies are simply taking away land that is not theirs by force and displacing the arabs.
                    They simply view Palestine as under occupation by brute force. You can see the gradual dissapearance of Palestinean territory on the map through the years
                    </p>
                    
                    <br></br>
                    
                    <img  src={ShrinkingMap} alt="shrinking palestine map"  className="cover"></img>

                    <p> The palestinians have multiple claims to the land</p>
                    <ul className='ListBasics'>

                        <li className="text-ListItem">
                            <span className='diff'> The Historical claim: </span> We have shed some light on this matter above.
                            How they describe their claim is as briefly follows:
                            
                            <p>
                            while maybe the current israelies have genetic ancestral links to the land, 
                            first of all so do the Palestenians, and secondly, this does not mean they can just take the land as if genetic links are actual basis for land ownership.
                            To them, that's like saying a Palestenian has genetic ties to the british or turkish ancestors so they should colonize england or turkey and the current citizens who have lived there and also share genetic ties to the past should leave.
                            Further they argue, most Israelies who came to Palestine are european and have been european for generations, meanwhile the arabs have lived in palestine for generations.
                            how can they be expected to just leave their homes where they and their grandparents have lived their whole lives? 
                            This point is further emphasized by the idea that Israel is a manufactured state, it had no unifying cutlure or language and they had to artificially recreate it, 
                            if so, how can the Israelies claim a tie to that land.
                            </p>

                            <p>
                            <span className='diff'>Simply speaking,</span> the Palestenians say, they have been living there for hundreds of years, the land was not given to them nor have they taken it by force, 
                                the entire region is arabic and so it has been for centuries. People can not just be granted ownership to a land where a nation of people lives and then expel them on a historic basis 
                                especially, if these people had no relationship to the land for centuries. They argue that this is by definition is called <span className='diff'> colonization </span> and so Israel in their eyes is a colonizer state.
                            </p>
                            <br></br>

                        </li>

                        <li className="text-listItem">

                            <span className='diff'>The legal claim:</span> This is what the Palestenians call "the promise of they who do not own to those who do not merit".
                            This claim is in two parts in their view, here we lay out what they say:
                            <ul className='ListBasics'>
                                <li className="text-listItem">

                                    <p>
                                    <span className='diff'>The first part is: </span>
                                    in 1917, the british empire declared  through the famous <span className='diff'>Balfour declaration</span> that Palestine would be given as a home land to the jews.
                                    later after the british empire seized the land from the Ottomans that was forcebly put into effect on the population already living there. 
                                    The palestinians here argue that the british promised a land they did not own (because they do not live there it was colonized during war, let alone that it was promised before they even took it)
                                    to a group who has no claim (the collective Jewish body which was comprised of a collection of european people trying to flee europe)
                                    This they argue has no legal claim since the british empire does not legally own the land but it has colonized it and then forced a colonizer on it. The legal claim would then lie with the original inhabitants of the land.
                                    They also argue that if the british were so pure so as to save the Jewish population why did they not give them a part of their own land then, why the land of someone else? 
                                    and why do the palestinians have to suffer the consequences of european oppression to the jews by being forced into colonization and exile?
                                    </p>
                                </li>
                                <li className="text-listItem">
                                    <p>
                                    <span className='diff'>The second part is: </span>
                                    After the Jews moved into Palestine and made parts of it their homeland by force, the united nation's security council (UNSC) which has the heighest legal authority in the world in addition to the international court of justice (ICJ)
                                    declared that the borders would be split as defined in resolution 242 with <span className="diff">two separate</span> states. Nowadays, there is only one state which not only controls its borders defined by the resolution,
                                    but also illegally (as defined by UNSC and ICJ many times) occupies the west bank, illegally expels people from their homes till this day, does not allow basic human rights for Palestenians such as:
                                    right to sovereignty, freedom of movement, freedom of expression, right of return, or religious freedom. They also have a blockade on gaza and the west bank whereby they cut power, water and imports to starve them.
                                    not only that, they also engage in illegal warfare such as the use of chemical weopons and targeting of civillians and mainly children under the guise of Hamas hididng among them.
                                    They argue that this definition has been so loosly defined that as far as Israel is concerned the entire Palestenian populaton is Hamas which since they enlisted them as terrorists have given themselves the right to exterminate them.
                                    </p>
                                    <img  src={TWO_STATES} alt="two states" className="Solcover"></img>
                                    <p>
                                    All of these activities and tactics are considered illegal under international law and breaches all resolutions, laws of war and treaties previously addressed on the matter.
                                    </p>
                                </li>
                            </ul>

                            
                            <p>
                            <span className='diff'>In summary,</span> they claim that there is no legal basis for the existance of the state of Israel to begin with, but even if it is to be recognized (which it has by the PLO) after being enforced upon their people and land,
                            they engage in such extremely aggressive and illegal policies and atrocities targetted against their people that is only possible due to the protection of the USA in international courts.
                            All in all that makes Israel in the Palestenian legal claim, at the very least a colonizer state and a terrorist appartheid state at most.
                            </p>
                        </li>

                        <li className="text-listItem">
                        
                        <span className='diff'>The religions claim:</span> Palestine is a holly land for all 3 Abrahamic religions, it should not be owned by a single group belonging to one of them.
                        before Israel, Moslems, Jews and christians have coexised and shared ownership of the land and so to each their holly land was granted. This is why Jerusalem is an international zone under international law.
                        The contineous desicration of the Islamic holly sights by the Israelies is breaching international law and owning the land on which these sights exist is not fair to the other two religious believers living on that land.


                        </li>


                    </ul>

                    

            </div>        
                    <br></br>
            


        <div className="expandable">

                    <h4 className="diff">What do they want?</h4> 

                    <span className="expand" onClick={()=>handleClick(PalSections[3])} > {`${hideWantsPal? '+':'-'}`} </span>

        </div>

            <div className={hideWantsPal?"hide":"show"}>

                    <p>
                    Palestenians naturally have many demans. Ending the blockade, self governance and recognition of them as a state, no evicitons from their homes, stopping the illegal settlements and returning that land.
                    They also want the return of the refugees and to keep Jeruselam as an international zone so that the holly sites can be kept outside the conflict.
                    Some palestinians want the Jews outside of Palestine entirely due to the aggression they and their ancestors have received and what they have experienced as home grabbing or evictions.
                    </p>
    

            </div> 
            
        </div>
    )
}

export default PalBasics
