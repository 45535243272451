import React, {useState} from 'react'
import parse from "html-react-parser";


function Item({date,evidence,desc,details,references}) {
    const [showDetails,setShowDetails]=useState(false);
    let ev= "";
    let description ="";
    let det="";

    const readMore=()=>{

        setShowDetails(!showDetails);

    }

    
    


    if(typeof evidence==="string"){
        
        ev=evidence;
        
        
    }else{
        ev="";
    } 
 
    
    if(typeof desc==="string"){
        
        description=desc;
        
        
    }else{
        description="";
    } 

    if(typeof details==="string"){
        
        det=details;
        
        
    }else{
        det="";
    } 
    
    return (
        
        <li className= 'timeline-item' data-aos="zoom-in" data-aos-delay="350"  data-aos-duration="500">
             
           <div>

              <time>{date}</time> 

               {/*
               *********NOTE 
               ---pictures/name.format is the image path 
               ---when using a video iframe make sure width and height are 100%
               */}

              <article className="evidence">
              
              {parse(ev)}

              </article>

             
              
              
             
           

              <br></br>


              {parse(description)} 

            
              <button className="btn"  onClick={()=>readMore()}> read {`${showDetails? 'less':'more'}`} &#8645; </button>

              <article className={showDetails?"show":"hide"}>


                 {parse(det)}

              <br></br>
              
              </article>


            

            <span className="references" >

              {references.map((refItem,index)=>( 
                        
                        
                   <a href={`${refItem.link}`} target="_blank" rel="noreferrer" key={index}>{refItem.name} </a>
                                
        
                                 ))}
            </span>
           
           </div>
       
        </li>
    )
}

export default Item
