import React from 'react';

function Welcome() {
    return (
        <article className="container">
           
            <h1>Welcome to the Is It Complicated wiki page.</h1>
           
            <br></br>

            <p>We are always told that the situation in Israel and Palestine is <span className="diff">"complicated".</span>
            This discourages people from learning about it and makes it easy to avoid the situation or any conversation about it.
            Unfortunately, this alienates the issue and decreases the chance of reaching a solution which is possible if we keep it alive and the information accessible.
            </p>
            <p>
            <span className="diff">Therefore,</span> the purpose here is to <span className="diff">simplify the situation</span> through taking a timeline approach to make it <span className="diff">visually digestible</span>,  <span className="diff">reduce the information clutter</span> and finally unlike traditional media,
            to have an as <span className="diff">unbiased</span> approach as possible by presenting plain facts/events with <span className="diff">no opinions/agendas</span> surrounding it. It is up to you to come to any <span className="diff">conclusions</span> and dive deeper.
            We hope you find this useful and informative.</p>
        
    </article>
    )
}

export default Welcome
