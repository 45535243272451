// import React from 'react'; //replace this with the line below later
import React,{useContext} from 'react';
import { Context } from './provider';

import Welcome from './Welcome';
import Basics from './Basics';
import Explanation from './Explanation';

function Header() {

    const {setOption,options}  = useContext(Context);

    const HandleClick=(e)=>{
        switch(e.target.className){
          case("btn btn-1850"):
            
              setOption(options[0]);
              break;
          case("btn btn-1950"):
            
              setOption(options[1]); 
              break;
          case("btn btn-2000"):
              
              setOption(options[2]);
              break;
          default:;
        }
      }

    return (

            <section className="intro">

                <Welcome />
                    
                <Basics/>
    
                <Explanation />

                <br></br>
                
                <h3> now, please choose a time period from below</h3>

                {/* **** Maybe add here component for each button with configurable types */}
                <div className="btns">
                        <button className="btn btn-1850" onClick={(e)=>{HandleClick(e)}}> 1850s-1950s</button>
                        <button className="btn btn-1950" onClick={(e)=>{HandleClick(e)}}> 1950s-2000s</button>
                        <button className="btn btn-2000" onClick={(e)=>{HandleClick(e)}}> 2000s-2050s</button>
                        {/* <button className="btn btn-gen" onClick={(e)=>{HandleClick(e)}}> general data</button> */}
                </div>

            </section>
       
    )
}

export default Header
