import React, {createContext,useState,useEffect} from 'react'
import axios from 'axios';

export const Context = createContext();


export const Provider = ({children}) =>{

    
    const options = ["1850-1950", "1950-2000", "2000-2050","gen"];
    const DEFAULT = "1850-1950";

    const [option,setOption]= useState(options[0]); 
    const [display,setDisplay]=useState([]);

    const handleResponse = (res)=>{
        //ID schema: ddmmyynn
        //nn: num refers to the order of the event on that day, say first,second, third event of the day...etc
        const Data=[];
        for(let i=0; i<res.dates.length;i++){
           
          Data[i]={
              id:res.ids[i],
              date:res.dates[i],
              evidence:res.evidence[i],
              desc:res.desc[i],
              details:res.details[i],
              ref: [
                 {
                     link:res.ref1[i],
                     name: res.ref1Names[i]
                  },
                 {
                  link:res.ref2[i],
                  name: res.ref2Names[i]
                  },
                 {
                  link:res.ref3[i],
                  name: res.ref3Names[i]
                  }
              ]
      
          }
    
        }
        setDisplay(Data);
    
    }
    
      

    
   
    

    useEffect(()=>{


        const getData=()=>{
         
            if(option===DEFAULT){
    
                axios.get(`/api/data`).then(res=>{
                    
                    handleResponse(res.data);
                    
                })
            }else{
                axios.get(`/api/data/${option}`).then(res=>{
                    
                    handleResponse(res.data);
                    
                })
            }
        }
      
        getData();
        
       },[option]);

    return(
        <Context.Provider value={{option,setOption,options,display}}>
            {children}
        </Context.Provider>
    )

}