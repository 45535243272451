import React from 'react'
import Solutions from './Solutions'
import Closing from './Closing'

function Footer() {
    return (
        <div>

    <footer className="page-footer">
        <span>Thank you for taking the time to read </span>
        <h2>We hope you do not believe it is complicated as it is claimed </h2>
        {/* <a href="youtube.com" target="_blank">  </a> */}
        <Solutions />
        <Closing />

        
    </footer>
            
        </div>
    )
    
}

export default Footer
