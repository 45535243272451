import React,{useState} from 'react'
import TWO_STATES from '../images/TWO_STATES.jpg'
import ONE_STATE from '../images/ONE-STATE.jpg'


function Solutions() {
    const solutions =['OneState','TwoState','Separation'];
    const [hideOneState,setHideOneState]= useState(true);
    const [hideTwoState,setTwoState]= useState(true);
    const [hideSeparation,setSeparation]= useState(true);


     const handleClick=(section)=>{
        switch(section){
            case solutions[0]: setHideOneState(!hideOneState);break;
            case solutions[1]: setTwoState(!hideTwoState);break;
            case solutions[2]: setSeparation(!hideSeparation);break;
            default:;
        }
    }

    return (
        <div className="solutions-basics">    

            <h3>So, are there actually any solutions to the situation? </h3>

            <p>
                In order to understand the solutions proposed we need to understand the main topics of discussion. Currently, these are the main issues under concern:
            </p>
                <ul className="ListBasics">
                    <li className='text-ListItem'>
                       The question of the <span className="diff">refugees</span>

                    </li>
                    <li className='text-ListItem'>
                       The question of the <span className="diff">settlements</span>

                    </li>
                    <li className='text-ListItem'>
                       The question of <span className="diff">jerusalem</span>

                    </li>
                    <li className='text-ListItem'>
                       The question of the <span className="diff">state sovereignty</span>

                    </li>
                </ul>

            <br></br>
            
            

            <div className="expandable">

                    <h4 className="diff" >The one state solution </h4> 

                    <span className="expand" onClick={()=>handleClick(solutions[0])} > {`${hideOneState? '+':'-'}`} </span>

            </div>

                <div className={hideOneState?"hide":"show"}>

                    <p>
                       This means there is a single state where both Arabs and Jews live in a democracy.
                        They would share power in some kind of parliament and governing bodies either have to be mixed or rotational between them.
                        People usually reference Singapore as a model for that state. 
                        Also some times proposals suggest jerusalem to be an international zone meaning it is under no body's control.
                        In this solution, Palestenian refugees would be allowed the right of return just like the Jews.
                        The settlements would be up to negotiations, either split or kept as jewish neigbourhoods depending on how they decide to divide the whole land.
                    </p>

                    <br></br>
                    
                    <img  src={ONE_STATE} alt="one state" className="Solcover"></img>
                    
                    <h4 className="diff"> Is this solution valid?</h4>
                   
                    <p>
                    <span className="diff">First,</span> Israel would never accept it because it means they would relenquish <span className="diff">total</span> control over the land (it would not be a state just for the jews anymore), Allow the return of refugees and possibly give up some settlements.
                        but most importantly, there is an Israeli concern that Palestenians reproduce much faster than Israelies, so they would fear that they overtake the popular vote eventually.
                        Therefore, the only way for Israel to accept this is either under heavy international pressure, or if the land is entirely controlled by Israel and Palestenians have no governmental control or possibility for getting a majority.
                        This is basically an appartheid state by definition, similar to the actual current state of affairs but more legitimized. 
                    </p>
                    <p> 
                    <span className="diff">Second,</span> it is hard for the Palestenians to agree to this solution as they would be acknowledging the land which belongs to Israelies which they view as taken away from them by force, such as the settlements.
                        More importantly, they would not accept the conditions specified by Israel such as not allowing the right of return or the apartheid enforcements...etc.
                    </p>
                    <p> 
                        so it would seem that this solution is at least for now too Utopean after all, it is either a good democracy where palestineans are likely to take majority eventually or
                        an enforced apartheid state by Israelies.
                    </p>


                </div> 

                    <br></br>

            <div className="expandable">

                <h4 className="diff">The two state solution</h4> 
                
                <span className="expand" onClick={()=>handleClick(solutions[1])} > {`${hideTwoState? '+':'-'}`} </span>
            
            </div>

            <div className={hideTwoState?"hide":"show"}>
           

                    <p>
                     This is the most comonly referneced solution and is the current one under proposition ever sunce resolution 242 by the security council.
                     It entails 2 separate states each with its own government, army, resources, sovereignty...etc.
                     It would be deinfed along the green line with the west bank (west of the jordan river) and gaza would be palestine and Israel would be the land in the middle.
                     In this scenario, the refugees can return to palestine, the settlements which are internationally (by the security council and the international court of justice) recognized as illegal would be given to Palestine.
                     each state would live separately with its people separated. jerusalem would be an international zone belonging to none of them. 
                    </p>

                    <br></br>
                    <img  src={TWO_STATES} alt="two states" className="Solcover"></img>
                    
                    <h4 className="diff"> So, why hasn't this been working so far?</h4>
                   
                    <p>
                    <span className="diff">First,</span> Israel is not recognizing a Palestenian government despite the PLO recognizing Israel as evident by blocking any attempt at the UN in legitimizing Palestine as a state.
                    This is motivated by their belief that the whole land belongs to the Jews by right or by force. Also motivated by similar notions, the government supports and helps Israelies increase and fortify the Illegal settlements by military support or infrastructure supply while simaltaneously cutting off similar resources for Palestenians.
                    The situation is not made easier when countries such as the USA recognizes jerusalem as the capital of israel and moves their embassy there as it breaches the international zone part of the resolution.
                    It is also worth noting that Israel has a complete blockade on the Palestenian territories by sea or land and blocks any UN resolution in favor of Palestine with the help of the united states.
                    Israel also has a major military and massive military foreign support while Palestinians are not allowed a military body or sovereignty.
                    </p>
                    <p> 
                    <span className="diff">Second,</span>  the Palestenians have not had a proper united front for a very long time. Motivated by Israeli aggression and utter powerlessnes as well as international abandonment, there have been gurella military attempt such as Hamas and others which Israel is considering terrorist action.
                    This does not help the peace process. The west bank government has been consistently failing its people and not being able to reach a proper settlement with Isearl as well.
                    What this shows is that there is only Iseael and Palestine is not even properly represented by an entity to be respected as a state despite the efforts of the PLO.
                    </p>
                    <p> 
                        so it would seem that this solution while the most likely is intentionally being put aside by the extremely aggressive policies Israel undertakes and the chaotic behavior the Palestenians are forced into which helps feed the Israeli motivation and does not help them.
                    </p>

            </div>        
                    <br></br>
            


        <div className="expandable">

                    <h4 className="diff">The separation solutiion</h4> 

                    <span className="expand" onClick={()=>handleClick(solutions[2])} > {`${hideSeparation? '+':'-'}`} </span>

        </div>

            <div className={hideSeparation?"hide":"show"}>

            

                    <p>
                    This solution has been recently circulating as a possible one, whereby countries in the surrounding nations such as Egypt would give part of their land such as Sinai in this example to Palestine.
                    Israel would have the entire land and palestineans can live somewhere else in peace. Thereby, Israelies get what they want and Palestineans get a separate non appartheid land where they can live in peace and sovereignty.
                    In this scenario, the refugees can return, however, jerusalem would be Jewish and the settlements would remain.
                    </p>

                    

                    <h4 className="diff"> Is this solution valid?</h4>

                    <p>
                    Well, for the Israelies that would be ideal for obvious reasons but for the Palestenians, it would be abandoning their land, losing their homes, resources and everything, their point would be why do THEY have to leave?
                    Not to mention, the trust issues Palestenians would have in terms of will Israel keep the blockade? will they bomb them? will they be allowed to have a military?
                    </p>
                    <p>
                    However, due to the extreme fatigue that has been growing over the years, many palestineans would rather live in peace than live on the land, so if this solution is true we may see an unexpected acceptance.
                    </p>
    

            </div> 

            
            
        </div>

        

        
    )
}
export default Solutions;
